import React from "react";

import useUserCurriculumQuery from "./useUserCurriculumQuery";

export default function useHasCurriculumAccessQuery() {
  const {
    curriculum,
    isCurriculumLoading,
    curriculumError,
  } = useUserCurriculumQuery();

  const hasCurriculumAccess = React.useMemo(() => {
    if (isCurriculumLoading || curriculumError) {
      return undefined;
    }

    return !!curriculum;
  }, [curriculum, isCurriculumLoading, curriculumError]);

  return {
    hasCurriculumAccess,
    isCurriculumLoading,
    curriculumError,
  };
}
