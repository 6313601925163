import React from "react";
import PropTypes from "prop-types";
import * as PrimitiveSlider from "@radix-ui/react-slider";

export function Root({
  value,
  defaultValue,
  step = 1,
  maxValue = 100,
  minValue = 0,
  disabled = false,
  name,
  className,
  children,
  onChange,
}) {
  return (
    <PrimitiveSlider.Root
      defaultValue={defaultValue}
      value={value}
      min={minValue}
      max={maxValue}
      step={step}
      disabled={disabled}
      name={name}
      className={className}
      onValueChange={onChange}
    >
      {children}
    </PrimitiveSlider.Root>
  );
}

export function Track({ className, children }) {
  return (
    <PrimitiveSlider.Track className={className}>
      {children}
    </PrimitiveSlider.Track>
  );
}

export function Thumb({ className, children }) {
  return (
    <PrimitiveSlider.Thumb className={className}>
      {children}
    </PrimitiveSlider.Thumb>
  );
}

export function Range({ className, children }) {
  return (
    <PrimitiveSlider.Range className={className}>
      {children}
    </PrimitiveSlider.Range>
  );
}

Root.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  defaultValue: PropTypes.arrayOf(PropTypes.number),
  step: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onChange: PropTypes.func,
};

Range.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Track.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

Thumb.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
