import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { StarIcon } from "@heroicons/react/solid";

const VARIANTS = {
  chip: "chip",
  limited: "limited",
  new: "new",
  premium: "premium",
  earned: "earned",
  sponsored: "sponsored",
  featured: "featured",
};

const variantsLabels = {
  [VARIANTS.chip]: `Current`,
  [VARIANTS.limited]: `Limited`,
  [VARIANTS.new]: `New`,
  [VARIANTS.premium]: (
    <>
      <StarIcon className="h-4 w-4 shrink-0 text-white stroke-0 mr-[2px]" />
      Premium
    </>
  ),
  [VARIANTS.earned]: `Earned`,
  [VARIANTS.sponsored]: `Sponsored`,
  [VARIANTS.featured]: `Featured`,
};

const variantsClassNames = {
  [VARIANTS.chip]: `rounded-full gn-text-xs bg-white text-gray-900 font-bold px-[10px]`,
  [VARIANTS.limited]: `rounded-sm gn-text-sm bg-gold text-gray-900 font-bold px-xs`,
  [VARIANTS.new]: `rounded-sm gn-text-sm bg-mint text-gray-900 font-bold px-xs`,
  [VARIANTS.premium]: `rounded-sm gn-text-sm bg-pink text-white font-bold px-xs`,
  [VARIANTS.earned]: `rounded-sm gn-text-sm bg-white text-gray-900 font-bold px-xs`,
  [VARIANTS.featured]: `rounded-sm gn-text-sm bg-blue-300 text-gray-900 font-bold px-xs`,
  [VARIANTS.sponsored]: `rounded bg-purple gn-text-xs text-white px-xs`,
};

export default function Tag({ variant, label, className = "" }) {
  return (
    <span
      className={twMerge(
        "inline-flex items-center py-[4px]",
        variantsClassNames[variant],
        className,
      )}
    >
      {label || variantsLabels[variant]}
    </span>
  );
}

Tag.VARIANTS = VARIANTS;

Tag.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};
