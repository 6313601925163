/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { OverlayProvider } from "react-aria";

import { AssetsConfigProvider } from "./contexts/AssetsConfig";
import * as Accordion from "./components/Accordion";
import * as Dialog from "./components/Dialog";
import * as Switch from "./components/Switch";
import * as Menu from "./components/Menu";
import * as Popover from "./components/Popover";
import * as HeroUnit from "./components/HeroUnit";
import * as Tooltip from "./components/Tooltip";
import * as Slider from "./components/Slider";
import * as NavigationMenu from "./components/NavigationMenu";
import * as Meter from "./components/Meter";
import * as Tabs from "./components/Tabs";
import * as ScrollArea from "./components/ScrollArea";
import Button, { BUTTON_VARIANTS, BUTTON_SIZES } from "./components/Button";
import * as RadioGroup from "./components/RadioGroup";
import * as Alert from "./components/Alert";

// COMPONENTS
export { default as BackgroundVideo } from "./components/BackgroundVideo";
export { default as BlogCard } from "./components/BlogCard";
export { default as BlogHeroSection } from "./components/BlogHeroSection";
export { default as Progress } from "./components/Progress";
export { default as CircularProgress } from "./components/CircularProgress";
export { default as Columns } from "./containers/Columns";
export { default as ContentTag } from "./components/ContentTag";
export { default as Image } from "./components/Image";
export { default as ImageTile } from "./components/ImageTile";
export { default as GameHero } from "./components/GameHero";
export { default as Header } from "./components/Header";
export { default as HeroVideo } from "./components/HeroVideo";
export { default as Loader } from "./components/SkeletonLoader/Loader";
export { default as MediaCard } from "./components/MediaCard";
export { default as Newsletter } from "./components/Newsletter";
export { default as PressCard } from "./components/PressCard";
export { default as PressFooter } from "./components/PressFooter";
export { default as PrintableCard } from "./components/PrintableCard";
export { default as Rating } from "./components/Rating";
export { default as PrintableSection } from "./components/PrintableSection";
export { default as Select } from "./components/Select";
export { default as SplashScreen } from "./components/SplashScreen";
export { default as Spotlight } from "./components/Spotlight";
export { default as VideoCard } from "./components/VideoCard";
export { default as VideoHeader } from "./components/VideoHeader";
export { default as VideoFooter } from "./components/VideoFooter";
export { default as ConfirmNewsletterSubscription } from "./components/ConfirmNewsletterSubscription";
export { default as ExternalLinkDisclaimer } from "./components/ExternalLinkDisclaimer";
export { default as TextField } from "./components/TextField";
export { default as AgeGateBox } from "./components/AgeGateBox";
export { default as TalkyChamp } from "./components/TalkyChamp";
export { default as Checkbox } from "./components/Checkbox";
export { default as AlertBox } from "./components/AlertBox";
export { default as Autocomplete } from "./components/Autocomplete";
export { default as DateField } from "./components/DateField";
export { default as PrivacyPolicyLink } from "./components/PrivacyPolicyLink";
export { default as TermsOfServiceLink } from "./components/TermsOfServiceLink";
export { default as NumberField } from "./components/NumberField";
export { default as Pressable } from "./components/Pressable";
export { Accordion };
export { Popover };
export { Dialog };
export { Switch };
export { Menu };
export { HeroUnit };
export { Tooltip };
export { Slider };
export { NavigationMenu };
export { Meter };
export { Tabs };
export { Button, BUTTON_VARIANTS, BUTTON_SIZES };
export { ScrollArea };
export { RadioGroup };
export { Alert };
export { default as Tag } from "./components/Tag";
export { default as Modal } from "./components/Modal";
export { default as AgeGatedPressable } from "./components/AgeGatedPressable";
export { default as Textarea } from "./components/Textarea";
export { default as CallToActionLabel } from "./components/CallToActionLabel";
export { default as Skeleton } from "./components/Skeleton";
export { default as VisuallyHidden } from "./components/VisuallyHidden";

// ICONS
export {
  ArrowRightIcon,
  BookmarkIcon,
  BookOpenIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  CheckIcon,
  ClockIcon,
  DocumentIcon,
  PlayIcon,
  PauseIcon,
  PencilIcon,
  MenuAlt1Icon,
  XIcon,
  StarIcon,
  HeartIcon,
  RefreshIcon,
  SelectorIcon,
  DotsVerticalIcon,
  EyeOffIcon,
  EyeIcon,
} from "@heroicons/react/solid";

export {
  PencilIcon as PencilOutlineIcon,
  UsersIcon as UsersOutlineIcon,
  DownloadIcon as DownloadOutlineIcon,
  StarIcon as StarOutlineIcon,
  ClockIcon as ClockOutlineIcon,
  SearchIcon as SearchOutlineIcon,
  FilterIcon as FilterOutlineIcon,
  MenuIcon as MenuOutlineIcon,
  XIcon as XOutlineIcon,
  ChevronDownIcon as ChevronDownOutlineIcon,
  HeartIcon as HeartOutlineIcon,
  SwitchHorizontalIcon as SwitchHorizontalOutlineIcon,
  UserIcon as UserOutlineIcon,
  QuestionMarkCircleIcon as QuestionMarkCircleOutlineIcon,
  LogoutIcon as LogoutOutlineIcon,
  BellIcon as BellOutlineIcon,
  ArrowLeftIcon as ArrowLeftOutlineIcon,
  ArrowRightIcon as ArrowRightOutlineIcon,
  ExternalLinkIcon as ExternalLinkOutlineIcon,
  ExclamationCircleIcon as ExclamationCircleOutlineIcon,
  PlayIcon as PlayOutlineIcon,
  LinkIcon as LinkOutlineIcon,
  PlusIcon as PlusOutlineIcon,
  TrashIcon as TrashOutlineIcon,
  AcademicCapIcon as AcademicCapOutlineIcon,
  EmojiHappyIcon as EmojiHappyOutlineIcon,
  InformationCircleIcon as InformationCircleOutlineIcon,
  FireIcon as FireOutlineIcon,
} from "@heroicons/react/outline";

// TEMPLATES
export { default as MaintenancePage } from "./templates/MaintenancePage";
export { default as FourOFour } from "./templates/FourOFour";
export { default as FiveHundred } from "./templates/FiveHundred";

// PROVIDERS
export { OverlayProvider, AssetsConfigProvider };

// HOOKS

// UTILS

export { classNames } from "../utils/classnames";
